
<template>
    <div>
        <el-form :model="form" label-width="90px" :rules="rules" ref="forms">
            <el-form-item label="设备编号" prop="deviceNo">
                <!-- {{form,78999}} -->
                <el-select v-model="form.deviceNo" @change="updeviceNoData" filterable :disabled="titleIndex == 1?true:false">
                    <el-option v-for="(item,index) in deviceListData" :key="index" :label="item.deviceNo" :value="item.deviceNo"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="请选择时间" prop="time">
                <el-date-picker :disabled="titleIndex == 1?true:false" @change="upTime" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
            <el-table ref="singleTable" :data="form.variable" style="width: 100%">
                <el-table-column property="var_name" label="名称" width="160" align="center"></el-table-column>
                <el-table-column label="值" prop="value">
                    <template slot-scope="scope">
                        <template v-if="scope.row.var_index!='009'">
                            <template v-if="scope.row.var_index =='911'">
                                <el-row>
                                    <el-col :span="8" v-for="(item,index) in statusData" :key="index">
                                        <el-checkbox v-model="item.value">{{ item.label }}</el-checkbox>
                                    </el-col>
                                </el-row>
                            </template>
                            <template v-else-if="scope.row.var_index =='031'">
                                <el-row>
                                    <template v-if="form.deviceTypeId =='HS_XSCSBMQLLJ'">
                                        <el-col :span="8" v-for="(ite,index) in statusData031" :key="index">
                                            <el-checkbox v-model="ite.value">{{ ite.label }}</el-checkbox>
                                        </el-col>
                                    </template>
                                    <template v-else>
                                        <el-col :span="8" v-for="(ite,index) in statusData031" :key="index">
                                            <el-checkbox v-if="index<=3" v-model="ite.value">{{ ite.label }}</el-checkbox>
                                        </el-col>
                                    </template>
                                </el-row>
                            </template>

                            <!-- 031 -->
                            <template v-else>
                                <el-input size="mini" v-model="scope.row.value"></el-input>
                            </template>
                        </template>

                        <!-- <div v-else>
              <el-upload :headers="tokenHeader" :action="baseURL +'/Files/Upload'" :file-list="fileList" ref="uploadPic" list-type="picture-card" name="files" :on-success="handleUploadSuccess" :on-exceed="handleExceed" :limit="1">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabledImage" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
                        </div>-->
                    </template>
                </el-table-column>
                <!-- <el-table-column label="时间" prop="value">
                <template slot-scope="scope">
                    <el-date-picker
                        disabled
                        v-model="scope.row.time"
                        type="datetime"
                        placeholder="选择日期时间">
                        </el-date-picker>
                </template>
                </el-table-column>-->
            </el-table>
        </el-form>
        <el-dialog :visible.sync="dialogImageVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import * as basicDataApi from "@/api/basicDataApi/basicDataApi"; //基础资料接口
export default {
    props: {
        form: {
            type: Object,
            default: () => {
                return {
                    time: "",
                    deviceNo: "",
                    variable: [],
                };
            },
        },
        deviceListData: {
            type: Array,
            default:[]
        },
        titleIndex: {
            type: Number,
            default: 0,
        },
        fileList: {
            type: Array,
            default: "",
        },
    },
    name: "",
    components: {},
    data() {
        return {
            // deviceListData: [],
            statusData: [
                { label: "工作交流电停电报警", value: false },
                { label: "蓄电池电压报警", value: false },
                { label: "水位超限报警", value: false },
                { label: "流量超限报警", value: false },
                { label: "水质超限报警", value: false },
                { label: "流量仪表故障报警", value: false },
                { label: "电机开停状态", value: false },
                { label: "水位仪表故障报警", value: false },
                { label: "水压超限报警", value: false },
                { label: "温度超限报警", value: false },
                { label: "监测站IC卡功能报警", value: false },
                { label: "定值控制报警", value: false },
                { label: "剩余水量的下限报警", value: false },
                { label: "监测站箱门状态报警", value: false },
                { label: "备用1", value: false },
                { label: "备用2", value: false },
            ],
            statusData031: [
                { label: "水位计1", value: false },
                { label: "水位计2", value: false },
                { label: "水位计3", value: false },
                { label: "水位计4", value: false },

                { label: "换能器1", value: false },
                { label: "换能器2", value: false },
                { label: "换能器3", value: false },
                { label: "换能器4", value: false },
                { label: "换能器5", value: false },
                { label: "换能器6", value: false },
                { label: "换能器7", value: false },
                { label: "换能器8", value: false },

                { label: "换能器9", value: false },
                { label: "换能器10", value: false },
                { label: "换能器11", value: false },
                { label: "换能器12", value: false },
                { label: "换能器13", value: false },
                { label: "换能器14", value: false },
                { label: "换能器15", value: false },
                { label: "换能器16", value: false },

                { label: "换能器17", value: false },
                { label: "换能器18", value: false },
                { label: "换能器19", value: false },
                { label: "换能器20", value: false },
                { label: "换能器21", value: false },
                { label: "换能器22", value: false },
                { label: "换能器23", value: false },
                { label: "换能器24", value: false },

                { label: "换能器25", value: false },
                { label: "换能器26", value: false },
                { label: "换能器27", value: false },
                { label: "换能器28", value: false },
                { label: "换能器29", value: false },
                { label: "换能器30", value: false },
                { label: "换能器31", value: false },
                { label: "换能器32", value: false },
            ],
            disabledImage: false,
            dialogImageUrl: "",
            dialogImageVisible: false,

            tokenHeader: { "X-Token": getToken() },
            baseURL: process.env.VUE_APP_BASE_API, // api的base_url
            baseImgURL: process.env.VUE_APP_BASE_IMG_URL, // api的imgurl
            rules: {
                deviceNo: [
                    {
                        required: true,
                        message: "设备编码不能为空",
                        trigger: "blur",
                    },
                ],
                time: [
                    {
                        required: true,
                        message: "请选择时间",
                        trigger: "blur",
                    },
                ],
            },
            attribute: [
                ["HS_CKYTHZM"], //闸门
                [
                    "HS_XSCSBMQLLJ",
                    "HS_KFSCSBMQLLJ",
                    "HS_WXSCSBMQLLJ",
                    "HS_BXECMQLLJ",
                    "HS_SWLLJ",
                ], //流量计
                ["HS_SWJ"], //水位计
            ],
            variable: [
                // 闸门     004 005  911   007   011~016
                // 流量计   004 005  911   031    001  002
                // 水位计   004 005  911   003
                [
                    {
                        unit: "",
                        var_guid: "0053200003_011",
                        var_index: "011",
                        var_name: "电机数据11",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_012",
                        var_index: "012",
                        var_name: "电机数据12",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_013",
                        var_index: "013",
                        var_name: "电机数据13",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_014",
                        var_index: "014",
                        var_name: "电机数据14",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_015",
                        var_index: "015",
                        var_name: "电机数据15",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_016",
                        var_index: "016",
                        var_name: "电机数据16",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_007",
                        var_index: "007",
                        var_name: "闸位",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_004",
                        var_index: "004",
                        var_name: "流量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_005",
                        var_index: "005",
                        var_name: "累计水量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_911",
                        var_index: "911",
                        var_name: "报警和设备状态",
                        time: "",
                        value: "",
                    },
                    // {
                    //     "unit": "",
                    //     "var_guid": "0053200003_009",
                    //     "var_index": "009",
                    //     "var_name": "图片",
                    //     "time": "",
                    //     "value": ""
                    // },
                ],
                [
                    {
                        unit: "",
                        var_guid: "0053200003_001",
                        var_index: "001",
                        var_name: "流量计水位",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_002",
                        var_index: "002",
                        var_name: "流量计水深",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_031",
                        var_index: "031",
                        var_name: "换能器和水位计状态",
                        time: "2024/01/11 16:34:42",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_004",
                        var_index: "004",
                        var_name: "流量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_005",
                        var_index: "005",
                        var_name: "累计水量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_911",
                        var_index: "911",
                        var_name: "报警和设备状态",
                        time: "",
                        value: "",
                    },
                    // {
                    //     "unit": "",
                    //     "var_guid": "0053200003_009",
                    //     "var_index": "009",
                    //     "var_name": "图片",
                    //     "time": "",
                    //     "value": ""
                    // },
                ],
                [
                    {
                        unit: "",
                        var_guid: "0053200003_003",
                        var_index: "003",
                        var_name: "水位计水位",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_004",
                        var_index: "004",
                        var_name: "流量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_005",
                        var_index: "005",
                        var_name: "累计水量",
                        time: "",
                        value: "",
                    },
                    {
                        unit: "",
                        var_guid: "0053200003_911",
                        var_index: "911",
                        var_name: "报警和设备状态",
                        time: "",
                        value: "",
                    },
                    // {
                    //     "unit": "",
                    //     "var_guid": "0053200003_009",
                    //     "var_index": "009",
                    //     "var_name": "图片",
                    //     "time": "",
                    //     "value": ""
                    // },
                ],
            ],
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.statusData = [
            { label: "工作交流电停电报警", value: false },
            { label: "蓄电池电压报警", value: false },
            { label: "水位超限报警", value: false },
            { label: "流量超限报警", value: false },
            { label: "水质超限报警", value: false },
            { label: "流量仪表故障报警", value: false },
            { label: "电机开停状态", value: false },
            { label: "水位仪表故障报警", value: false },
            { label: "水压超限报警", value: false },
            { label: "温度超限报警", value: false },
            { label: "监测站IC卡功能报警", value: false },
            { label: "定值控制报警", value: false },
            { label: "剩余水量的下限报警", value: false },
            { label: "监测站箱门状态报警", value: false },
            { label: "备用1", value: false },
            { label: "备用2", value: false },
        ];
        this.statusData031 = [
            { label: "水位计1", value: false },
            { label: "水位计2", value: false },
            { label: "水位计3", value: false },
            { label: "水位计4", value: false },

            { label: "换能器1", value: false },
            { label: "换能器2", value: false },
            { label: "换能器3", value: false },
            { label: "换能器4", value: false },
            { label: "换能器5", value: false },
            { label: "换能器6", value: false },
            { label: "换能器7", value: false },
            { label: "换能器8", value: false },

            { label: "换能器9", value: false },
            { label: "换能器10", value: false },
            { label: "换能器11", value: false },
            { label: "换能器12", value: false },
            { label: "换能器13", value: false },
            { label: "换能器14", value: false },
            { label: "换能器15", value: false },
            { label: "换能器16", value: false },

            { label: "换能器17", value: false },
            { label: "换能器18", value: false },
            { label: "换能器19", value: false },
            { label: "换能器20", value: false },
            { label: "换能器21", value: false },
            { label: "换能器22", value: false },
            { label: "换能器23", value: false },
            { label: "换能器24", value: false },

            { label: "换能器25", value: false },
            { label: "换能器26", value: false },
            { label: "换能器27", value: false },
            { label: "换能器28", value: false },
            { label: "换能器29", value: false },
            { label: "换能器30", value: false },
            { label: "换能器31", value: false },
            { label: "换能器32", value: false },
        ];
        let variableIndex = 0;
        this.attribute.forEach((item, index) => {
            item.forEach((it) => {
                if (it == this.form.deviceTypeId) {
                    variableIndex = index;
                }
            });
        });
        if (this.titleIndex == 1) {
            if (this.form.variable.length > 0) {
                this.form.variable.forEach((item) => {
                    // 报警状态
                    if (item.var_index == "911") {
                        if (item.value) {
                            let arr = item.value.split("");
                            arr.forEach((ite, index) => {
                                if (ite == "1") {
                                    this.statusData[index]["value"] = true;
                                }
                            });
                        }
                    }
                    // 换能器状态
                    if (item.var_index == "031") {
                        if (item.value) {
                            let arr = item.value.substring(4).split("");
                            let newarr = [];
                            arr.forEach((ite, index) => {
                                if (ite == "1") {
                                    newarr.push(true);
                                } else {
                                    newarr.push(false);
                                }
                            });
                            if (this.form.deviceTypeId == "HS_XSCSBMQLLJ") {
                                //全部
                                this.statusData031[3].value = newarr[0];
                                this.statusData031[2].value = newarr[1];
                                this.statusData031[1].value = newarr[2];
                                this.statusData031[0].value = newarr[3];

                                this.statusData031[11].value = newarr[4];
                                this.statusData031[10].value = newarr[5];
                                this.statusData031[9].value = newarr[6];
                                this.statusData031[8].value = newarr[7];
                                this.statusData031[7].value = newarr[8];
                                this.statusData031[6].value = newarr[9];
                                this.statusData031[5].value = newarr[10];
                                this.statusData031[4].value = newarr[11];

                                this.statusData031[19].value = newarr[12];
                                this.statusData031[18].value = newarr[13];
                                this.statusData031[17].value = newarr[14];
                                this.statusData031[16].value = newarr[15];
                                this.statusData031[15].value = newarr[16];
                                this.statusData031[14].value = newarr[17];
                                this.statusData031[13].value = newarr[18];
                                this.statusData031[12].value = newarr[19];

                                this.statusData031[27].value = newarr[20];
                                this.statusData031[26].value = newarr[21];
                                this.statusData031[25].value = newarr[22];
                                this.statusData031[24].value = newarr[23];
                                this.statusData031[23].value = newarr[24];
                                this.statusData031[22].value = newarr[25];
                                this.statusData031[21].value = newarr[26];
                                this.statusData031[20].value = newarr[27];

                                this.statusData031[35].value = newarr[28];
                                this.statusData031[34].value = newarr[29];
                                this.statusData031[33].value = newarr[30];
                                this.statusData031[32].value = newarr[31];
                                this.statusData031[31].value = newarr[32];
                                this.statusData031[30].value = newarr[33];
                                this.statusData031[29].value = newarr[34];
                                this.statusData031[28].value = newarr[35];
                            } else {
                                //部分

                                this.statusData031[3].value = newarr[0];
                                this.statusData031[2].value = newarr[1];
                                this.statusData031[1].value = newarr[2];
                                this.statusData031[0].value = newarr[3];
                            }
                        }
                    }
                });
            }
            let newVariable = [];
            newVariable = JSON.parse(
                JSON.stringify(this.variable[variableIndex])
            );
            newVariable.forEach((item) => {
                if (this.form.variable.length > 0) {
                    this.form.variable.forEach((ite) => {
                        if (item.var_index == ite.var_index) {
                            item.value = ite.value;
                        }
                    });
                }
            });
            this.form.variable = newVariable;
        }
        let searchQuery = {
            page: 1,
            limit: 999990,
        };
        // basicDataApi
        //     .jczlSbglSearch(searchQuery)
        //     .then((response) => {
        //         if (response.code == 200) {
        //             this.deviceListData = response.data;
        //         } else {
        //             this.deviceListData = [];
        //         }
        //     })
        //     .catch(() => {
        //         this.deviceListData = [];
        //     });
    },
    methods: {
        // 上传图片删除
        handleRemove(file) {
            this.$refs.uploadPic.clearFiles();
            this.form.variable.forEach((it) => {
                if (it.var_index == "009") {
                    it.value = "";
                }
            });
        },
        // 上传图片查看
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogImageVisible = true;
        },
        // 文件超出
        handleExceed() {
            this.$message.error("只允许上传一个文件");
            return;
        },
        //上传成功
        handleUploadSuccess(res, file) {
            if (res.code == 500) {
                this.$refs.uploadPic.clearFiles();
                this.$message.error("上传失败");
                return;
            }
            this.form.variable.forEach((it) => {
                if (it.var_index == "009") {
                    it.value = res.result[0].filePath;
                }
            });
        },
        upTime(e) {
            this.form.variable.forEach((item) => {
                item.time = e;
            });
        },
        // 返回数据
        returnForm() {
            this.form.variable.forEach((item) => {
                if (item.var_index == "911") {
                    let val = "";
                    this.statusData.forEach((item) => {
                        if (item.value == true) {
                            val += "1";
                        } else {
                            val += "0";
                        }
                    });
                    item.value = val + "0000000000000000";
                }
                if (item.var_index == "031") {
                    this.statusData031.forEach((item) => {
                        if (item.value == true) {
                            item.newValue = "1";
                        } else {
                            item.newValue = "0";
                        }
                    });
                    let vals =
                        "0000" +
                        this.statusData031[3].newValue +
                        this.statusData031[2].newValue +
                        this.statusData031[1].newValue +
                        this.statusData031[0].newValue;
                    vals +=
                        this.statusData031[11].newValue +
                        this.statusData031[10].newValue +
                        this.statusData031[9].newValue +
                        this.statusData031[8].newValue +
                        this.statusData031[7].newValue +
                        this.statusData031[6].newValue +
                        this.statusData031[5].newValue +
                        this.statusData031[4].newValue;
                    vals +=
                        this.statusData031[19].newValue +
                        this.statusData031[18].newValue +
                        this.statusData031[17].newValue +
                        this.statusData031[16].newValue +
                        this.statusData031[15].newValue +
                        this.statusData031[14].newValue +
                        this.statusData031[13].newValue +
                        this.statusData031[12].newValue;
                    vals +=
                        this.statusData031[27].newValue +
                        this.statusData031[26].newValue +
                        this.statusData031[25].newValue +
                        this.statusData031[24].newValue +
                        this.statusData031[23].newValue +
                        this.statusData031[22].newValue +
                        this.statusData031[21].newValue +
                        this.statusData031[20].newValue;
                    vals +=
                        this.statusData031[35].newValue +
                        this.statusData031[34].newValue +
                        this.statusData031[33].newValue +
                        this.statusData031[32].newValue +
                        this.statusData031[31].newValue +
                        this.statusData031[30].newValue +
                        this.statusData031[29].newValue +
                        this.statusData031[28].newValue;
                    item.value = vals;
                    // let vals =""
                    // this.statusData031.forEach((item,index)=>{
                    //     if(index ==0){
                    //         let newArr= JSON.parse(JSON.stringify(item.reverse()))
                    //         newArr.forEach((ite)=>{
                    //             if(ite.value ==true){
                    //                 vals+="1"
                    //             }else{
                    //                 vals+="0"
                    //             }
                    //         })
                    //         vals+="0000"+vals
                    //     }else{
                    //         let newArr1= JSON.parse(JSON.stringify(item.reverse()))
                    //         newArr1 .forEach((it)=>{
                    //             if(it.value ==true){
                    //                 vals+="1"
                    //             }else{
                    //                 vals+="0"
                    //             }
                    //         })

                    //     }

                    // })
                    // item.value = vals
                }
            });

            return this.form;
        },

        updeviceNoData(e) {
            this.statusData031 = [
                { label: "水位计1", value: false },
                { label: "水位计2", value: false },
                { label: "水位计3", value: false },
                { label: "水位计4", value: false },

                { label: "换能器1", value: false },
                { label: "换能器2", value: false },
                { label: "换能器3", value: false },
                { label: "换能器4", value: false },
                { label: "换能器5", value: false },
                { label: "换能器6", value: false },
                { label: "换能器7", value: false },
                { label: "换能器8", value: false },

                { label: "换能器9", value: false },
                { label: "换能器10", value: false },
                { label: "换能器11", value: false },
                { label: "换能器12", value: false },
                { label: "换能器13", value: false },
                { label: "换能器14", value: false },
                { label: "换能器15", value: false },
                { label: "换能器16", value: false },

                { label: "换能器17", value: false },
                { label: "换能器18", value: false },
                { label: "换能器19", value: false },
                { label: "换能器20", value: false },
                { label: "换能器21", value: false },
                { label: "换能器22", value: false },
                { label: "换能器23", value: false },
                { label: "换能器24", value: false },

                { label: "换能器25", value: false },
                { label: "换能器26", value: false },
                { label: "换能器27", value: false },
                { label: "换能器28", value: false },
                { label: "换能器29", value: false },
                { label: "换能器30", value: false },
                { label: "换能器31", value: false },
                { label: "换能器32", value: false },
            ];
            let newForm = this.deviceListData.find((item) => {
                return item.deviceNo == e;
            });
            this.form = Object.assign(this.form, newForm);
            let variable = 0;
            this.attribute.forEach((item, index) => {
                item.forEach((it) => {
                    if (it == newForm.deviceTypeId) {
                        variable = index;
                    }
                });
            });
            this.form.variable = [];
            this.form.variable = this.variable[variable];
            this.form.variable.forEach((item) => {
                item.value = "";
            });
            this.form.gprs_id = this.form.deviceNo;
            this.form.equ_guid = this.form.deviceNo;
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
