<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container" v-if="showSearch">
        <base-search :searchList="$lssjLssjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"  :dynamic='true' :setSearchWidth="'70%'" :setOperationWidth="'30%'"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%;" id="table_box_height">
        <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" :hasSelection="true" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @tableColumnClick="tableColumnClick"  @handleSelectionChange="handleSelectionChange" :otherColumn="otherColumn">
          <template v-slot:imgSrc="row">
            <el-image ref="myImg" v-if="row.rowData.screenshotPath" style="width: 24px; height: 24px;cursor: pointer;" :src="require('@/assets/img/thumbnail.png')" @click.stop="viewImg($event,row.rowData)"> </el-image>
            <!-- <img src="@/assets/img/thumbnail.png" alt="" class="Dosage_img" style="width: 24px; height: 24px;cursor: pointer;" @click="viewImg(row.rowData)"> -->
          </template>
        </basic-table >
      </div>


      <!-- 拖拽div --
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容--
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :receiveTreedata="orgsTree" :incomeTreeData="true" :isShowCheck="false" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne" :defaultChoose="defaultChoose"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容--
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" :hasSelection="true" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @tableColumnClick="tableColumnClick"  @handleSelectionChange="handleSelectionChange" :otherColumn="otherColumn">
              <template v-slot:imgSrc="row">
                <el-image ref="myImg" v-if="row.rowData.screenshotPath" style="width: 24px; height: 24px;cursor: pointer;" :src="require('@/assets/img/thumbnail.png')" @click.stop="viewImg($event,row.rowData)"> </el-image>
                <!-- <img src="@/assets/img/thumbnail.png" alt="" class="Dosage_img" style="width: 24px; height: 24px;cursor: pointer;" @click="viewImg(row.rowData)"> --
              </template>
            </basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>

    <!-- 添加弹窗 --> 
  
    <el-dialog class="dialog-mini user-dialog" id="aaa"  :title="title[titleIndex]" :visible.sync="FormDialog"  width="50%" @close="cancelDialog">
      <generalForm @cancelDia="cancelDia" v-if="FormDialog" :deviceListData="deviceListData"  ref="forms" :form="formData" :titleIndex="titleIndex" :fileList="fileList" ></generalForm>
      <div slot="footer">
          <el-button  size="mini" @click="cancelDia">取消</el-button>
          <el-button type="primary" size="mini"  @click="submitForm('forms')">提交</el-button>
        </div>
    </el-dialog>

    <el-dialog title="查看图片" :visible.sync="imgDialogVisible" width="30%" >
      <el-image :src="previewImgSrc" > </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="dialog-mini user-dialog" :title="'导出列选择'" :visible.sync="exportHeaderDia"  width="50%" @close="cancelHeaderDialog">
      <el-checkbox-group v-model="checkedHeaderList" @change="handleCheckedHeaderListChange">
        <el-row>
          <el-col  v-for="item in firstHeaderList" :span="8" :key="item.key">
            <el-checkbox :label="item.key" :key="item.key">{{item.description}}</el-checkbox>
          </el-col>
        </el-row>
        
      </el-checkbox-group>
      <div slot="footer">
          <el-button  size="mini" @click="cancelHeaderDialog">取消</el-button>
          <el-button type="primary" size="mini"  @click="handleDownExcel">提交</el-button>
        </div>
    </el-dialog>
    
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/historicalSupplement'//通用添加弹窗组件
  // import baseSearch from './baseSearch.vue'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  import { parseTime } from '@/utils/index'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm,
      // baseSearch
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        showSearch:false,
        checkedHeaderList:[],
        exportHeaderDia:false,
        fileList:[],
        formData:{},
        title:["补录","更改"],
        titleIndex:0,
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          queryKey:'',
          queryValue: '',
          TreeIds:[],
          StartTime:'',
          EndTime:'',
          key:"",
          QueryType:"0",
          timer:[new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),new Date()],
          HS_Area:""
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        excelName: `抄表记录${parseTime(new Date())}`,
        defaultChoose:[],//通过跳转过来的页面默认选中树
        FormDialog:false,//添加弹窗
        addform:{
          UserNumber:'',//用户编号
          SurfaceNum:'',//表编号
          SurfaceAddress:'',//表地址
          CurrentReading:'',//当前读数
        },
        FormList:{//表格列
          column:[
            {columnKey:'CurrentReading',columnDescription:'设备编号',columnType:'text',prop:'CurrentReading',width:'120',placeholder:"设备编号",step:0.0001,},
            {columnKey:'UserNumber',columnDescription:'软件版本号',columnType:'text',prop:'UserNumber',width:'120',placeholder:"软件版本号",},
            {columnKey:'SurfaceNum',columnDescription:'安装地点',columnType:'text',prop:'SurfaceNum',width:'120',placeholder:"安装地点",},
            {columnKey:'SurfaceAddress',columnDescription:'经度坐标',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"经度坐标",},
            {columnKey:'SurfaceAddress',columnDescription:'纬度坐标',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"纬度坐标",},
            {columnKey:'SurfaceAddress',columnDescription:'用水定额(m³)',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"用水定额",},
            {columnKey:'SurfaceAddress',columnDescription:'灌溉亩数(亩)',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"灌溉亩数",},
            {columnKey:'SurfaceAddress',columnDescription:'作物类型',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"作物类型",},
            {
              columnKey:'fatherAreaId',
              columnDescription:'所属机构',
              columnType:'selectAreaTree',
              prop:'fatherAreaId',
              width:'120',
              placeholder:"所属机构",
            },
            // {columnKey:'SurfaceAddress',columnDescription:'设备安装图片',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"设备安装图片",},
            {columnKey:'SurfaceAddress',columnDescription:'设备描述',columnType:'textarea',prop:'SurfaceAddress',width:'120',placeholder:"设备描述",},
            {columnKey:'fileName',columnDescription:'设备安装图片',columnType:'upload',prop:'fileName',width:'120',placeholder:"请选择设备安装图片",},
          ],
          //校验
          rule:{
            CurrentReading: [{ required: true, message: "当前读数不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'数据维护',//添加弹窗标题
        chooseList:[],//多选数据
        otherColumn:[//
          {
            label:'历史图片',
            slotName:'imgSrc',
          }
        ],
        previewImgSrc:[],//预览图片列表
        imgDialogVisible:false,//图片弹窗
        deviceListData:[],//设备数据
      }
    },
    watch: {
      
    },
    computed: {
      orgsTree() {
        return this.$store.state.allInstitution;
      },
      json_fields() {//导出主表数据处理
        let obj = {}
        this.firstHeaderList.length > 0 && this.firstHeaderList.forEach(item => {
          if (!item.comment) return
          if (item.key === 'orderType' || item.key === 'goodsType' || item.key === 'transferType') {
            obj[item.comment] = {
              field: item.key,
              callback: value => {
                return this.handleFilterState(value, item.key)
              }
            }
            return
          }
          obj[item.comment] = item.key
        })
        return obj
      }
    },
    filters: {
      
    },
    created() {
      let searchQuery = {
        page: 1,
        limit: 999990,
      };
      basicDataApi.jczlSbglSearch(searchQuery).then((response) => {
        if (response.code == 200) {
          this.deviceListData = response.data;
          this.deviceListData.forEach(i=>{
            i.dtCode = i.deviceNo
            i.name = i.installedLocation + '_' + i.deviceNo
          })
          this.$store.state.deviceListData = this.deviceListData;
          this.$lssjLssjSearch.column[0].seleceOption = this.deviceListData;
        } else {
          this.deviceListData = [];
        }
        this.showSearch = true
      }).catch(() => {
        this.deviceListData = [];
        this.showSearch = true
      });
    },
    mounted() {
      if(this.$route.query.areaId){//如果是页面跳转
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(this.$route.query.areaId)
        this.defaultChoose = []
        this.defaultChoose.push(this.$route.query.areaId)
        
      }
      // this.getList()

      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-70
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-70
          this.$forceUpdate()
        });
      })
      
    },
    methods: {
      // 选择表头事件
      handleCheckedHeaderListChange(e){
      },
      // 取消选择表头弹窗
      cancelHeaderDialog(){
        this.exportHeaderDia =false
      },
      // 提交
      submitForm(){
        
        this.$refs.forms.$refs.forms.validate((valid) => {
            if(valid){
              let data = JSON.parse(JSON.stringify(this.$refs.forms.returnForm()))
              let newvariable =[]
              data.variable.forEach((item)=>{
                item.last_active = data.time
                item.time = data.time
                item.history_data=[{ time: data.time,value: item.value}]
                newvariable.push(item) 
              })
              data.source = 2
              data.variable = newvariable
              data.last_active=JSON.parse(JSON.stringify(data.time))
              var newVariable = data.variable.filter(item => item.value !== null && item.value !== '');
              var newData = data
              newData.variable = newVariable
              basicDataApi.lssjAddOrUpdate(newData).then(response => {
                if(response.code == 200){
                  this.FormDialog =false
                  // this.getList()
                  this.$refs.forms.$refs.forms.resetFields();
                  if(this.titleIndex == 0){
                    this.$message.success('补录成功');
                  }else{
                    this.$message.success('编辑成功');
                  }
                }else{
                  if(this.titleIndex == 0){
                    this.$message.error('补录失败');
                  }else{
                    this.$message.error('编辑失败');
                  }
                }

              }).catch(()=>{
              })
            }
        })
    },
      // 弹框取消
      cancelDia(){
        this.FormDialog =false
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      // swith开关切换
      switchChange(e){
        var data={
          key:'isTotalMeter',
          value:e,
          userId:this.$store.state.userId
        }
        basicDataApi.saveisTotalMeter(data).then(response => {
          if(response.code == 200){
            localStorage.setItem('isTotalMeter', e);
            this.$store.state.isTotalMeter = e
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.HS_Area=data.id
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId, callback) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.chooseList.length <= 0 ){
              this.$message.error('请选择需要删除的数据！');
              return;
            }
            
            this.$confirm(`确定要删除吗?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              cancelButtonClass: 'message-style',
              type: 'warning'
            }).then(() => {
              this.loading = true
              basicDataApi.lssjBatchDelete({realDatas:this.chooseList}).then(response => {
                if(response.code == 200){
                  this.$message.success('删除成功');
                  this.loading = false
                  this.getList()
                }else{
                  this.loading = false
                }
              }).catch(()=>{
                this.loading = false
              })
            }).catch(() => {});
           

            // this.$message.success('删除成功');
            // this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://补录
            this.titleIndex = 0
            this.FormDialog = true
            this.formData = {
                last_active:"",
                time:"",
                deviceNo:"",
                variable:[]
            }
            break
          case 'btnEdit'://更改
            if(this.chooseList.length != 1){
              this.$message.error('请选择需要更改的一条数据！');
              return;
            }
            this.formData =JSON.parse(JSON.stringify(this.chooseList[0]))
            this.formData.variable = this.formData.hsDeviceOrgRealdata
            this.formData.deviceNo = JSON.parse(JSON.stringify(this.chooseList[0].deviceNo))
            this.formData.variable.forEach((item)=>{
              if(item.var_index =='009'){
                this.fileList=[
                  {
                    url:item.value,
                  }
                ]
            }
            })
            this.titleIndex = 1
            this.FormDialog = true
            break
          case 'btnExport':  //导出
            this.checkedHeaderList=[]
            this.exportHeaderDia =true
            // this.handleDownExcel()
            break;
          
          default:
            break
        }
      },
      // 导出
      handleDownExcel() {
        if(this.checkedHeaderList.length<=0){
          this.$message.error("请先选择需要导出的字段再进行导出")
          return
        }
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }else{
          this.$message.error("请先选择时间在进行搜索")
          return
        }
        let exportHeaderList={}
        this.checkedHeaderList.forEach((item)=>{
          exportHeaderList[item] = this.firstHeaderList.filter((it)=>{return it.key == item})[0].description
        })
        searchQuery.PropInfos = exportHeaderList
        basicDataApi.lssjExport(searchQuery).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/'+ response.result
            window.open(url)
            this.cancelHeaderDialog()
            // window.location.href = url
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
      // 导出状态转换
      handleFilterState(val, key) {
        const typeIds = {
          orderType: 'SYS_INBOUNDTYPE',
          goodsType: 'SYS_GOODSTYPE',
          transferType: 'SYS_SHIPTYPE',
          status: 'SYS_ORDERSTATUS',//单据状态转换
          externalType:'SYS_EXTERNALTYPE',
        }
        const obj = this.typeDataLists.length > 0 && this.typeDataLists.find(item => item.typeId === typeIds[key])
        const arr = obj && obj.typeDatas || []
        const item = arr.length > 0 && arr.find(item => item.dtCode === val) && arr.find(item => item.dtCode === val).name || ''
        return item || val || ''
      },
      // 导出状态转换-单据状态转换
      handleFilterRderstayus(val,key){
        const arr = key === 'status' ? this.$store.state.rderstayus : ''
        return key === 'asnStatus' ? val === 0 ? '否' : '是' : arr.find(item => item.dtCode === val).name
      },
      // 获取数据
      getList() {
        if(!this.listQuery.key){
          this.$message.error("请选择设备进行搜索")
          return
        }
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }else{
          this.$message.error("请先选择时间在进行搜索")
          return
        }
        this.loading = true
        basicDataApi.sjglLssjSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            // response.columnHeaders.forEach((item) => { // 首字母小写
            //   item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            // })
            // this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)1
            // // this.firstHeaderList.forEach(i=>{
            // //   i.columnWidth = 160
            // // })
            // this.tableData = response.data
            // this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.HS_Area =''
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        console.log(this.addform)
        if(!this.addform.UserNumber && !this.addform.SurfaceNum && !this.addform.SurfaceAddress){
          this.$notify({
            message: '至少输入用户编号、表编号、表地址中的一个',
            type: 'warning',
            duration: 2000
          });
          return
        }
        basicDataApi.cbxxRgcb(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.addform={
              UserNumber:'',//用户编号
              SurfaceNum:'',//表编号
              SurfaceAddress:'',//表地址
              CurrentReading:'',//当前读数
            }
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.FormDialog = false
        })
      },
      //点击图片
      viewImg(e,row){
        this.previewImgSrc =  row.screenshotPath
        this.imgDialogVisible = true
        console.log(e,33,this.previewImgSrc)
      },
    },
  }

</script>

<style lang="scss" scoped>
#aaa ::v-deep .el-dialog__body{
  height:  calc(100vh - 250px);
}
  
</style>
