var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _vm.showSearch
          ? _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("base-search", {
                  staticStyle: { display: "inline-block" },
                  attrs: {
                    searchList: _vm.$lssjLssjSearch,
                    listQuery: _vm.listQuery,
                    dynamic: true,
                    setSearchWidth: "70%",
                    setOperationWidth: "30%",
                  },
                  on: {
                    search: _vm.handleFilter,
                    "btn-event": _vm.onBtnClicked,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c(
          "div",
          {
            staticClass: "bg-white",
            staticStyle: { height: "100%" },
            attrs: { id: "table_box_height" },
          },
          [
            _c("basic-table", {
              attrs: {
                tableHeight: _vm.tableHeight,
                tableHeaderList: _vm.firstHeaderList,
                tableData: _vm.tableData,
                listQuery: _vm.listQuery,
                hasSelection: true,
                tableTotal: _vm.tableTotal,
                otherColumn: _vm.otherColumn,
              },
              on: {
                pagination: _vm.handleCurrentChange,
                tableRowClick: _vm.tableRowClick,
                tableColumnClick: _vm.tableColumnClick,
                handleSelectionChange: _vm.handleSelectionChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "imgSrc",
                  fn: function (row) {
                    return [
                      row.rowData.screenshotPath
                        ? _c("el-image", {
                            ref: "myImg",
                            staticStyle: {
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                            },
                            attrs: {
                              src: require("@/assets/img/thumbnail.png"),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.viewImg($event, row.rowData)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: {
            id: "aaa",
            title: _vm.title[_vm.titleIndex],
            visible: _vm.FormDialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.FormDialog = $event
            },
            close: _vm.cancelDialog,
          },
        },
        [
          _vm.FormDialog
            ? _c("generalForm", {
                ref: "forms",
                attrs: {
                  deviceListData: _vm.deviceListData,
                  form: _vm.formData,
                  titleIndex: _vm.titleIndex,
                  fileList: _vm.fileList,
                },
                on: { cancelDia: _vm.cancelDia },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancelDia } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("forms")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.imgDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDialogVisible = $event
            },
          },
        },
        [
          _c("el-image", { attrs: { src: _vm.previewImgSrc } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.imgDialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: {
            title: "导出列选择",
            visible: _vm.exportHeaderDia,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportHeaderDia = $event
            },
            close: _vm.cancelHeaderDialog,
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handleCheckedHeaderListChange },
              model: {
                value: _vm.checkedHeaderList,
                callback: function ($$v) {
                  _vm.checkedHeaderList = $$v
                },
                expression: "checkedHeaderList",
              },
            },
            [
              _c(
                "el-row",
                _vm._l(_vm.firstHeaderList, function (item) {
                  return _c(
                    "el-col",
                    { key: item.key, attrs: { span: 8 } },
                    [
                      _c(
                        "el-checkbox",
                        { key: item.key, attrs: { label: item.key } },
                        [_vm._v(_vm._s(item.description))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.cancelHeaderDialog },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleDownExcel },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }